/* eslint-disable no-useless-escape */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingModal from '../../components/CustomModal/LoadingModal';
import CalculatorVersionSelector from '../../components/CalculatorForm/FieldTypes/CalculatorVersionSelector';

import '../../components/Footer/Footer';
import '../../components/NavBar/NavBar';
import { config } from '../../constants';

import './Calculator.css';
import Outputs from '../../components/Outputs/Outputs';
import CalculatorForm from '../../components/CalculatorForm/CalculatorForm';
import useCalculatorData from '../../api/useCalculatorData';
import useSaveQuote from '../../api/useSaveQuote';
import useCalculatorsVersion from '../../api/useCalculatorsVersion';
import { CircularProgress, List } from '@mui/material';
import { MIN_DEV_FEE } from '../../components/CalculatorForm/helpers';
import { isSalesDesign } from '../../utils';

function Calculator() {
  const sf_host = process.env.REACT_APP_SF_HOST;
  const [calculatorVersion, setSelectedCalculatorVersion] = useState(undefined);
  const [inputs, setInputs] = useState(undefined);
  const [outputs, setOutputs] = useState(undefined);
  const [lastAction, setLastAction] = useState(undefined);
  const [isSDSales, setIsSDSales] = useState(false);

  const [outputDestination, setOutputDestination] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [searchParams, ] = useSearchParams();
  const action = searchParams.get('action') || 'new'; // view, edit or clone
  const selectedQuoteId = searchParams.get('id'); // existing
  const selectedSystemDesign = searchParams.get('systemDesign'); // existing system design
  const selectedOpportunityId = searchParams.get('opportunityId'); // new sales quote
  const quoteId = searchParams.get('quoteId'); // new sales quote
  let selectedBusinessName = useRef(searchParams.get('businessName') || 'No Business Name');
  let projectId = useRef(searchParams.get('projectId') || 'No Project ID');
  const [showOutputs, setShowOutputs] = useState(false);
  const {
    data: { systemDesignData, opportunityData, selectedQuoteData, previousFormData } = {},
    loading: loadingData,
    isFetched: isFetchedData
  } = useCalculatorData(selectedSystemDesign, selectedQuoteId, selectedOpportunityId);
  const { data: availableCalcs, loading: loadingCalcs} = useCalculatorsVersion();
  const saveQuoteMutation = useSaveQuote();
  function renderHeaderName() {
    if (action === 'view') {
      return "View";
    } else if (action === 'edit') {
      return "Edit";
    } else if (action === 'clone') {
      return "Clone";
    } else {
      return "New";
    }
  }
  if(!loadingCalcs && availableCalcs && availableCalcs.length > 0 && !calculatorVersion) {
    if (searchParams.get('version')) {
          setSelectedCalculatorVersion(searchParams.get('version'));
        } else {
          setSelectedCalculatorVersion(availableCalcs[0].version.replace('.xlsm', ''));
        }
  }

  useEffect(() => {
    async function getSystemType() {
      if (!systemDesignData) return;
      const result = await isSalesDesign(systemDesignData);
      setIsSDSales(result);
    }
    getSystemType();
  }, [systemDesignData]);

  if (loadingData || !isFetchedData)
    return <CircularProgress color='primary' />
  function renderLoadingModal() {
    return (
      <>
        <LoadingModal
          show={loading || loadingData}
          rotateNames={lastAction !== undefined && !saveQuoteMutation.isPending}
        />
      </>
    );
  }

  const disableSave = () => {

    const isLastActionUndefined = lastAction === undefined;
    const areOutputsEmpty = outputs.length === 0;

    return isLastActionUndefined || areOutputsEmpty;
  }

  // export to salesforce
  const handleSave = async () => {
    setLoading(true);
    let url = '/api/export-to-sf';
    if (lastAction !== undefined) {
      url = url + "?action=" + lastAction;
    }
    url = url + '&version=' + calculatorVersion;
    if (action === 'edit') {
      url = url + "&id=" + selectedQuoteId;
    }

    // Check if the dev fee is below the minimum threshold
    const solverDevFee = outputs.find(output => output.data_variable_element === 'sr_finl_dev_fee_per_watt_out')?.value;
    // solverDevFee is a string like "$0.1845"
    const parsedDevFee = typeof solverDevFee === 'string' ? parseFloat(solverDevFee.replace('$', '')) : solverDevFee || 0;
    const userEmail = localStorage.getItem('email');
    const isFinanceUser = ['seth', 'anson', 'max'].some(name => userEmail?.toLowerCase().includes(name));
    if ((parsedDevFee < MIN_DEV_FEE) && !isFinanceUser) {
      toast.error('Note: Only Finance can save quotes with a dev fee < $0.1845. Please adjust the dev fee or contact Finance.', {
        position: toast.POSITION.TOP_RIGHT
      });
      setLoading(false);
      return;
    }

    const form_epc_total_per_watt_sd = Number(outputs.find(output => output.data_variable_element === 'epc_total_per_watt_sd')?.value.replace('$', ''));
    const previousFormData_epc_total_per_watt_sd = Number(previousFormData.epc_total_per_watt_sd);

    const engDesignChanged = !isSDSales && (form_epc_total_per_watt_sd != 0 && (form_epc_total_per_watt_sd).toFixed(2) != (previousFormData_epc_total_per_watt_sd).toFixed(2));
    const salesDesignChanged = isSDSales && (form_epc_total_per_watt_sd).toFixed(2) != (previousFormData_epc_total_per_watt_sd).toFixed(2);
    const errorChangeEPCMessage = 'EPC costs have been adjusted and this quote cannot be saved. To save a quote with a different EPC cost change the cost in the Salesforce system design.';
    if (engDesignChanged || salesDesignChanged) {
        toast.error(
          errorChangeEPCMessage,
          { position: toast.POSITION.TOP_RIGHT }
        );
        setLoading(false);
        return;
    }

    await saveQuoteMutation.mutateAsync({
      inputs: inputs,
      selectedSystemDesign: selectedSystemDesign,
      selectedOpportunityId: selectedOpportunityId,
      outputDestination: outputDestination,
      url: url
    }, {
      onSuccess: (data) => {
        toast.success('Successfully created sales quote ' +
          (action === 'edit' ? selectedQuoteId : data.id), {
          position: toast.POSITION.TOP_RIGHT
        });
        clearFormData();
        setLoading(false);
      },
      onError: (error) => {
        toast.warning('Failed to create sales quote, retry. Error message is: ' +
          error, {
            position: toast.POSITION.TOP_RIGHT
          });
        setLoading(false);
      }
    });
  }

  const clearFormData = () => {
    setShowOutputs(false);
    setOutputs(undefined);
    setOutputDestination(undefined);
  }

  // change status to engineer review
  const handleSubmitToEng = () => {
    setLoading(true);
    axios.patch('/api/quotes?id=' + selectedQuoteId, config)
      .then(data => {
        setLoading(false);
        if (data.status === 200) {
          toast.success('Successfully submitted quote to engineering review', {
            position: toast.POSITION.TOP_RIGHT
          });
          navigate('/');
        } else {
          toast.warning('Failed to update sales quote, retry', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      }).catch(function() {
        setLoading(false);
        toast.warning('Failed to update sales quote, retry', {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  }

  function getOpportunityLink(opportunity) {
    const id = opportunity?.Id;
    const name = opportunity?.Name;
    if (sf_host.includes('http')) {
      return (
        <a
          target="_blank"
          rel="noreferrer"
          href={`${sf_host}/lightning/r/Opportunity/${id}/view`}
        >
          {name}
        </a>
      )
    } else
      return (
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://${sf_host}/lightning/r/Opportunity/${id}/view`}
        >
          {name}
        </a>
      )
  }

  function getQuoteLink(quoteId) {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://${sf_host}/lightning/r/Quote/${quoteId}/view`}
      >
        {quoteId}
      </a>
    )
  }

  if (!opportunityData)
    return <div>Loading...</div>

  return (
    <div style={{ overflow: 'auto' }}>
      {renderLoadingModal()}
      <Container style={{ height: '1100px' }}>
        {selectedQuoteId ? <p style={{ marginTop: '40px', marginBottom: '20px' }}>
          <Link to='/' style={{ color: '#FF7E0B' }}>Opportunities</Link> / &nbsp;
          {getQuoteLink(selectedQuoteId)} : &nbsp;
          {getOpportunityLink(opportunityData)} / {opportunityData && opportunityData.StageName} / {!!quoteId && `Quote #${quoteId}`} / {calculatorVersion}</p> : undefined}
        <h2 style={{ marginTop: '40px', marginBottom: '20px' }}>{renderHeaderName()} Sales Quote</h2>
        {action !== 'view' ? (<><Row className='add-space section-white'>
          <Col>
            <p>Calculator Version</p>
            <CalculatorVersionSelector
              availableCalcs={availableCalcs}
              calculatorVersion={calculatorVersion}
              setSelectedCalculatorVersion={setSelectedCalculatorVersion}
              action={action}
            />
          </Col>
          <Col md="auto" className='add-space'>
            {action === 'new' || action === 'clone' || (opportunityData && opportunityData.StageName === 'Engineer Review') ? undefined :
              <Button variant="success" size="lg" onClick={() => handleSubmitToEng()}>Submit to Engineering</Button>}
          </Col>
          <Col style={{"display": "flex", "flexFlow": "row-reverse"}}>
            {getOpportunityLink(opportunityData)}
          </Col>
        </Row></>) : undefined}
        <Row className='add-space'>
          <Col>
            <Card sx={{ marginBottom: 7}}>
              <CardContent sx={{ height: '80vh'}}>
                <List style={{maxHeight: '100%', overflow: 'auto'}}>
                    {calculatorVersion && opportunityData &&
                    (action === 'view' ? selectedQuoteData : true) &&
                    (action === 'edit' ? selectedQuoteData : true) &&
                    (action === 'clone' ? selectedQuoteData : true) &&
                    (selectedSystemDesign ? (!loadingData && isFetchedData ) : true) &&
                        <CalculatorForm
                          action={action}
                          systemDesignData={systemDesignData}
                          opportunityData={opportunityData}
                          opportunityId={selectedOpportunityId}
                          prevFormData={previousFormData}
                          outputDestination={outputDestination}
                          setOutputDestination={setOutputDestination}
                          setInputs={setInputs}
                          selectedSystemDesign={selectedSystemDesign}
                          opportunityState={opportunityData.project_state__c}
                          calculatorVersion={calculatorVersion}
                          setOutputs={setOutputs}
                          setShowOutputs={setShowOutputs}
                          setLastAction={setLastAction}
                          sdIsEngineered={!isSDSales}
                      />}
                </List>
              </CardContent>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardContent sx={{ height: '80vh'}} className='outputs-section' h>
              { calculatorVersion && opportunityData &&
                <Outputs systemDesignData={systemDesignData}
                    selectedSystemDesign={selectedSystemDesign}
                    outputs={outputs}
                    isView={action === 'view'}
                    action={action}
                    showOutputs={showOutputs}
                    outputDestination={outputDestination}
                    calculatorVersion={calculatorVersion}
                    businessName={selectedBusinessName.current}
                    projectId={projectId.current}
                    onOutputsChange={setOutputs}
                    prevFormData={previousFormData}
                    quoteData={selectedQuoteData}
                    disableSave={disableSave}
                    handleSave={handleSave}
                    selectedFinancingType={(inputs  && inputs?.financing_type) || previousFormData?.financing_type}
                    opportunityData={opportunityData}
                    opportunityId={selectedOpportunityId}
                  />}
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );

}

export default Calculator;
